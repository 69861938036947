import {
  isNil,
  isString,
  isNumber,
  cloneDeep,
  template,
  findIndex,
  pick,
} from 'lodash';
import taxLib from '@nsftx/seven-gravity-tax-service';
import nsoftMoney from '../../../utility/nsoftMoney';
import eventBus from '../../../utility/eventBus';
import {
  ticket as ticketApi, lastTickets, ticketCancel, ticketCheck,
} from '../../../api';
import mutationTypes from './mutationTypes';
import setValue from '../../../utility/setValue';
import notificationTypes from '../notifications/notificationTypes';
import helpers from '../../../utility/helpers';

export default {
  async addBet({
    commit,
    dispatch,
    getters,
    rootGetters,
  }, bet) {
    const isValidationSuccessful = await dispatch('validateAddBet', bet);

    if (!isValidationSuccessful) return;

    await dispatch('setMinBetPayment', getters.rules.minBetAmount.value);
    await dispatch('setMinTicketPayment', getters.rules.minBetAmount.value);
    await dispatch('setMinBetPaymentForBet', bet);

    if (rootGetters.isFreeBetMode) {
      const isFreeBetValid = await dispatch('validateFreeBet', bet);
      if (!isFreeBetValid) {
        // Change the message text to be more intuitive
        await dispatch('notifications/setNotification', {
          message: rootGetters.translations.general_free_bet_invalid_bet_payment,
          notificationTimeout: 5000,
          status: 'negative',
        }, { root: true });
        return;
      }
    }

    commit(mutationTypes.ADD_BET, bet);

    await dispatch('addPreBet', {});

    if (getters.isCombo) {
      await dispatch('calculateTotalOdds');
      await dispatch('findDuplicateEventsAndLock', getters.ticket);
      await dispatch('validateUserBalance');

      // if (getters.ticket.length <= 1) {
      //   await dispatch('setTotalPaymentValue', getters.minBetPayment);
      // }

      await dispatch('calculatePossibleWinCombo');
    }

    if (getters.isManualMode) {
      await dispatch('callTotalPaymentSplitAlgorithm');
    } else if (getters.isSingle) {
      await dispatch('calculateTotalPayment');
    }

    await dispatch('validateAllBets');

    eventBus.$emit('formatBetslipInputValue');
    eventBus.$emit('betAddedToBetslip');

    if (getters.channelType === 'Retail' && getters.config.isFuturePerBetAllowed) {
      const { numEvents, id } = bet;
      await dispatch('setFutureRoundsPerBet', { futureRounds: numEvents, betId: id });
      dispatch('setFutureRoundsForEqualEvents');
    }
  },
  async addPreBet({
    commit, dispatch, getters, rootGetters,
  }, bet) {
    commit(mutationTypes.ADD_PRE_BET, bet);

    if (isNil(bet.bet)) return;

    await dispatch('calculatePossibleWinPreBet');
    await dispatch('setIsFreeBetPayinDisabled', false);

    // We want to do all of this only on mobile
    // Desktop should only show bet info
    if (!rootGetters.isDesktop) {
      await dispatch('setMinBetPayment', getters.rules.minBetAmount.value);
      await dispatch('validateTotalPayment');

      if (!getters.isManualMode) {
        await dispatch('calculateTotalPayment');
      }

      eventBus.$emit('formatBetslipInputValue');
    }
  },
  betPaymentDelete({ commit, getters, dispatch }, { betId, combinations }) {
    if (combinations) {
      const combinationMinPayment = getters.combinationMinPayment(combinations);

      commit(mutationTypes.BET_PAYMENT_DELETE, { betId, value: combinationMinPayment });
    } else {
      commit(mutationTypes.BET_PAYMENT_DELETE, { betId, value: getters.minBetPayment });
    }

    dispatch('setIsManualMode', false);

    dispatch('calculateTotalPayment');

    if (getters.isCombo) {
      dispatch('calculateTotalOdds');
    }
  },
  betPaymentDeleteMax({ commit, getters, dispatch }, { betId }) {
    commit(mutationTypes.BET_PAYMENT_DELETE, { betId, value: getters.maxBetPayment });

    dispatch('setIsManualMode', false);

    dispatch('calculateTotalPayment');

    if (getters.isCombo) {
      dispatch('calculateTotalOdds');
    }
  },
  async cancelTicket({ dispatch, rootGetters }, ticket) {
    const { config } = rootGetters;
    const { id } = ticket;
    const user = rootGetters['user/user'];

    if (config.platformName === 'nextGen') {
      const { igniteInstance } = rootGetters;
      const { providerBetslipId } = ticket;
      const prepareBetslipData = {
        providerBetslipId,
        bets: [id],
      };
      const result = await igniteInstance.prepareCancelBet(prepareBetslipData);
      if (result.status !== 'OK') {
        // eslint-disable-next-line no-console
        console.warn('PREPARE CANCEL BET EVENT RESPONDED BY PARENT WITH NOK STATUS');
        return;
      }
    }
    dispatch('setIsLastTicketsInProgress', true);

    await ticketCancel.cancel(config, user, id).then(() => {
      dispatch('setIsLastTicketsInProgress', false);
      if (config.platformName === 'nextGen') eventBus.$emit('igniteTicketCancelled', id);
    }).catch((error) => {
      if (config.platformName === 'nextGen') {
        const { igniteInstance } = rootGetters;
        const { providerBetslipId } = ticket;
        igniteInstance.failCancelBet(providerBetslipId);
      }
      dispatch(
        'notifications/setNotification',
        {
          message: error, // todo error message?
          status: 'negative',
        },
        { root: true },
      );
    });
  },
  async callTotalPaymentSplitAlgorithm({ dispatch }) {
    const isValid = await dispatch('validateTotalPayment');

    if (!isValid) return;

    await dispatch('totalPaymentSplitAlgorithm');
    await dispatch('calculatePossibleWin');
  },
  async calculateTotalPayment({ commit, getters, dispatch }) {
    let totalPayment = 0;

    if (!isNil(getters.selectedBet) && !getters.ticket.length) {
      if (getters.selectedBet.combinations) {
        const combinationMinPayment = getters
          .combinationMinPayment(getters.selectedBet.combinations);

        totalPayment += combinationMinPayment > getters.minBetPayment
          ? combinationMinPayment
          : getters.minBetPayment;
      } else {
        totalPayment += getters.minBetPayment;
      }
    }

    getters.ticket.forEach((ticket) => {
      totalPayment += Number(ticket.stake || getters.minBetPayment) * (ticket.numEvents || 1);
    });

    commit(mutationTypes.SET_TOTAL_PAYMENT, getters.formatNumber(totalPayment));

    await dispatch('validateTotalPayment');
    await dispatch('calculatePossibleWin');
  },
  calculateTotalPaymentRange({ commit, getters }) {
    let minPaymentAllowed = 0;
    let maxPaymentAllowed = 0;

    if (!isNil(getters.selectedBet) && !getters.ticket.length) {
      let minPayment = getters.rules.minBetAmount.value;

      if (getters.selectedBet.combinations) {
        const combinationMinPayment = getters
          .combinationMinPayment(getters.selectedBet.combinations);
        minPayment = combinationMinPayment > getters.minBetPayment
          ? combinationMinPayment
          : getters.minBetPayment;
      }

      minPaymentAllowed += minPayment;
      maxPaymentAllowed += getters.rules.maxBetAmount.value;
    }

    if (getters.isSingle) {
      getters.ticket.forEach((bet) => {
        let minPayment = getters.rules.minBetAmount.value;
        const maxPayment = getters.rules.maxBetAmount.value;

        if (!isNil(bet.combinations)) {
          const combinationMinPayment = getters.combinationMinPayment(bet.combinations);

          minPayment = combinationMinPayment > getters.minBetPayment
            ? combinationMinPayment
            : getters.minBetPayment;
        }

        minPaymentAllowed += minPayment * bet.numEvents;
        maxPaymentAllowed += maxPayment * bet.numEvents;
      });
    }

    if (getters.isCombo) {
      if (getters.ticket.length) {
        minPaymentAllowed = getters.rules.minBetAmount.value;
        maxPaymentAllowed = getters.rules.maxBetAmount.value;
      }
    }

    commit(mutationTypes.SET_MIN_PAYMENT_ALLOWED, getters.formatNumber(minPaymentAllowed));
    commit(mutationTypes.SET_MAX_PAYMENT_ALLOWED, maxPaymentAllowed);
  },
  async clearBetslip({ dispatch, commit, getters }) {
    commit(mutationTypes.REMOVE_BETS);

    await dispatch('addPreBet', {});
    await dispatch('calculateTotalPayment');

    if (getters.isCombo) {
      await dispatch('findDuplicateEventsAndLock', getters.ticket);
    }

    await dispatch('calculateTotalOdds');
    await dispatch('calculatePossibleWinCombo');
    await dispatch('calculatePossibleWinPreBet');

    await dispatch('setPaymentPerBetValue', '');
    await dispatch('setTotalPaymentValue', getters.rules.minBetAmount.value);
    await dispatch('setIsManualMode', false);
    dispatch('setFutureRoundsValue', 1);

    dispatch('notifications/clearNotification', { type: notificationTypes.MAX_BET_COUNT }, { root: true });
    eventBus.$emit('formatBetslipInputValue');
    eventBus.$emit('RemoveInvalidBets');
    eventBus.$emit('deactivateEditMode');
  },
  calculateTotalOdds({ commit, getters }) {
    let totalOdds = 0;

    getters.ticket.forEach((bet, index) => {
      if (index === 0) {
        totalOdds = bet.odds;
      } else {
        totalOdds *= bet.odds;
      }
    });

    commit(mutationTypes.SET_TOTAL_ODDS, getters.decimalFormatNumber(
      getters.formatNumber(totalOdds),
    ));
  },
  calculatePossibleWinCombo({ commit, getters }) {
    const totalPaymentTax = taxLib.calculateTax(
      getters.config.taxes.payin.policy,
      Number(getters.totalPayment),
    );

    let possibleWin = 0;
    let possiblePayinTax = 0;

    if (getters.ticket.length) {
      if (totalPaymentTax.payer === 'Player') {
        possibleWin = getters.multiplyWithoutFloatIssues(
          getters.roundFloatNumber(
            getters.totalPayment - totalPaymentTax.taxAmount,
          ), getters.totalOdds,
        );
      } else {
        possibleWin = getters.multiplyWithoutFloatIssues(
          Number(getters.totalPayment), getters.totalOdds,
        );
      }

      possiblePayinTax = totalPaymentTax.taxAmount;
    }

    const possibleWinRounded = getters.roundFloatNumber(possibleWin);

    commit(mutationTypes.SET_POSSIBLE_WIN_COMBO, {
      possibleWinnings: getters.decimalFormatNumber(getters.formatNumber(
        nsoftMoney.getMainUnit(possibleWinRounded, getters.config.currency),
      )),
      possiblePayinTax: getters.decimalFormatNumber(getters.formatNumber(
        nsoftMoney.getMainUnit(possiblePayinTax, getters.config.currency),
      )),
    });
  },
  calculatePossibleWin({ dispatch, getters }) {
    if (getters.config.isPaymentPerBetStrategy) {
      dispatch('calculateTaxPerBet');
    } else {
      dispatch('calculateTaxPerTicket');
    }
  },
  calculatePossibleWinPreBet({ dispatch, getters }) {
    if (getters.config.isPaymentPerBetStrategy) {
      dispatch('calculateTaxPerBetPreBet');
    } else {
      dispatch('calculateTaxPerTicketPreBet');
    }
  },
  calculateTaxPerBetPreBet({ getters, dispatch }) {
    let totalPaymentTax = 0;
    let totalStake = 0;
    let possibleWinnings = 0;
    let possiblePayoutTax = 0;
    let possiblePayout = 0;

    const bet = {
      stake: getters.totalPayment,
      ...getters.selectedBet,
    };

    const betTaxValues = taxLib.calculateTax(getters.config.taxes.payin.policy, bet.stake);
    const betTax = betTaxValues.taxAmount;

    let betStake = 0;

    if (betTaxValues.payer === 'Player') {
      betStake = bet.stake - betTax;
    } else {
      betStake = bet.stake;
    }

    const betStakeRounded = nsoftMoney.getMainUnit(betStake, getters.config.currency);

    let betWinnings = 0;

    if (bet.combinations) {
      if (bet.maxOdds) {
        betWinnings = betStakeRounded * bet.maxOdds;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (bet.odds) {
        betWinnings = betStakeRounded * bet.odds;
      }
    }

    const betWinningsRounded = nsoftMoney.getMainUnit(betWinnings, getters.config.currency);
    const betPayoutTaxValues = taxLib.calculateTax(
      getters.config.taxes.payout.policy,
      betWinnings,
    );
    const betPayoutTaxRounded = nsoftMoney.getMainUnit(
      betPayoutTaxValues.taxAmount,
      getters.config.currency,
    );
    const betPayout = betWinningsRounded - betPayoutTaxRounded;

    totalPaymentTax += betTax * (bet.numEvents || 1);
    totalStake += betStakeRounded * (bet.numEvents || 1);
    possibleWinnings += betWinningsRounded * (bet.numEvents || 1);
    possiblePayoutTax += betPayoutTaxRounded * (bet.numEvents || 1);
    possiblePayout += betPayout * (bet.numEvents || 1);

    dispatch('setPossibleWinPreBet', {
      totalStake,
      possibleWinnings: nsoftMoney.getMainUnit(possibleWinnings, getters.config.currency),
      possiblePayout,
      possiblePayoutTax: nsoftMoney.getMainUnit(possiblePayoutTax, getters.config.currency),
      possiblePayinTax: nsoftMoney.getMainUnit(totalPaymentTax, getters.config.currency),
    });
  },
  calculateTaxPerTicketPreBet({ getters, dispatch }) {
    const totalPaymentTax = taxLib.calculateTax(
      getters.config.taxes.payin.policy,
      getters.totalPayment,
    );

    let totalStake = 0;

    if (totalPaymentTax.payer === 'Player') {
      totalStake = getters.totalPayment - totalPaymentTax.taxAmount;
    } else {
      totalStake = getters.totalPayment;
    }

    const totalStakeRounded = nsoftMoney.getMainUnit(totalStake, getters.config.currency);
    let ticketWinnings = 0;

    const bet = {
      stake: getters.totalPayment,
      ...getters.selectedBet,
    };

    const betTaxValues = taxLib.calculateTax(getters.config.taxes.payin.policy, bet.stake);
    const betTax = betTaxValues.taxAmount;
    const isPlayer = betTaxValues.payer === 'Player';

    let betStake = 0;

    if (isPlayer) {
      betStake = bet.stake - betTax;
    } else {
      betStake = bet.stake;
    }

    let betWinnings = 0;

    if (bet.combinations) {
      if (bet.maxOdds) {
        betWinnings = betStake * bet.maxOdds / bet.combinations;
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (bet.odds) {
        betWinnings = betStake * bet.odds;
      }
    }

    ticketWinnings += betWinnings * (bet.numEvents ?? 1);

    const payoutTaxValues = taxLib.calculateTax(
      getters.config.taxes.payout.policy, ticketWinnings,
    );
    const possiblePayoutTax = payoutTaxValues.taxAmount;
    const possiblePayout = ticketWinnings - possiblePayoutTax;

    const possiblePayoutTaxRounded = nsoftMoney.getMainUnit(
      possiblePayoutTax,
      getters.config.currency,
    );
    const possiblePayoutRounded = nsoftMoney.getMainUnit(possiblePayout, getters.config.currency);

    dispatch('setPossibleWinPreBet', {
      totalStake: totalStakeRounded,
      possibleWinnings: nsoftMoney.getMainUnit(ticketWinnings, getters.config.currency),
      possiblePayout: possiblePayoutRounded,
      possiblePayoutTax: possiblePayoutTaxRounded,
      possiblePayinTax: nsoftMoney.getMainUnit(totalPaymentTax.taxAmount, getters.config.currency),
    });
  },
  calculateTaxPerBet({ getters, commit }) {
    let totalPaymentTax = 0;
    let totalStake = 0;
    let possibleWinnings = 0;
    let possiblePayoutTax = 0;
    let possiblePayout = 0;

    getters.ticket.forEach((bet) => {
      const betTaxValues = taxLib.calculateTax(getters.config.taxes.payin.policy, bet.stake);
      const betTax = betTaxValues.taxAmountRounded;

      let betStake = 0;

      if (betTaxValues.payer === 'Player') {
        betStake = bet.stake - betTax;
      } else {
        betStake = bet.stake;
      }

      const betStakeRounded = getters.roundFloatNumber(betStake);

      let betWinnings = 0;

      if (bet.combinations) {
        if (bet.maxOdds) {
          betWinnings = getters.multiplyWithoutFloatIssues(betStakeRounded, bet.maxOdds);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (bet.odds) {
          betWinnings = getters.multiplyWithoutFloatIssues(betStakeRounded, bet.odds);
        }
      }

      const betWinningsRounded = getters.roundFloatNumber(betWinnings);
      const betPayoutTaxValues = taxLib.calculateTax(
        getters.config.taxes.payout.policy,
        betWinningsRounded,
        betStakeRounded,
      );

      const betPayoutTaxRounded = betPayoutTaxValues.taxAmountRounded;
      const betPayout = betWinningsRounded - betPayoutTaxRounded;

      totalPaymentTax += betTax * (bet.numEvents || 1);
      totalStake += betStakeRounded * (bet.numEvents || 1);
      possibleWinnings += betWinningsRounded * (bet.numEvents || 1);
      possiblePayoutTax += betPayoutTaxRounded * (bet.numEvents || 1);
      possiblePayout += betPayout * (bet.numEvents || 1);
    });

    const possibleWinningsRounded = getters.roundFloatNumber(possibleWinnings);
    const possiblePayoutTaxRounded = getters.roundFloatNumber(possiblePayoutTax);

    commit(mutationTypes.SET_POSSIBLE_WIN, {
      totalStake,
      possibleWinnings: getters.decimalFormatNumber(getters.formatNumber(
        nsoftMoney.getMainUnit(possibleWinningsRounded, getters.config.currency),
      )),
      possiblePayout,
      possiblePayoutTax: getters.decimalFormatNumber(getters.formatNumber(
        nsoftMoney.getMainUnit(possiblePayoutTaxRounded, getters.config.currency),
      )),
      possiblePayinTax: getters.decimalFormatNumber(getters.formatNumber(
        nsoftMoney.getMainUnit(totalPaymentTax, getters.config.currency),
      )),
    });
  },
  calculateTaxPerTicket({ getters, commit }) {
    const totalPaymentTax = taxLib.calculateTax(
      getters.config.taxes.payin.policy,
      getters.totalPayment,
    ).taxAmountRounded;

    let totalStake = 0;

    if (totalPaymentTax.payer === 'Player') {
      totalStake = getters.totalPayment - totalPaymentTax;
    } else {
      totalStake = getters.totalPayment;
    }

    const totalStakeRounded = nsoftMoney.getMainUnit(totalStake, getters.config.currency);

    let ticketWinnings = 0;

    getters.ticket.forEach((bet) => {
      const betTaxValues = taxLib.calculateTax(getters.config.taxes.payin.policy, bet.stake);
      const betTax = betTaxValues.taxAmountRounded;
      const isPlayer = betTaxValues.payer === 'Player';

      let betStake = 0;

      if (isPlayer) {
        betStake = bet.stake - betTax;
      } else {
        betStake = bet.stake;
      }

      let betWinnings = 0;

      if (bet.combinations) {
        if (bet.maxOdds) {
          betWinnings = getters
            .multiplyWithoutFloatIssues(betStake, bet.maxOdds) / bet.combinations;
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (bet.odds) {
          betWinnings = getters.multiplyWithoutFloatIssues(betStake, bet.odds);
        }
      }

      ticketWinnings += betWinnings * bet.numEvents;
    });

    const payoutTaxValues = taxLib.calculateTax(
      getters.config.taxes.payout.policy, ticketWinnings,
    );
    const possiblePayoutTax = payoutTaxValues.taxAmount;
    const possiblePayout = ticketWinnings - possiblePayoutTax;

    const possiblePayoutTaxRounded = nsoftMoney.getMainUnit(
      possiblePayoutTax,
      getters.config.currency,
    );
    const possiblePayoutRounded = nsoftMoney.getMainUnit(possiblePayout, getters.config.currency);
    const ticketWinningsRounded = getters.roundFloatNumber(ticketWinnings);


    commit(mutationTypes.SET_POSSIBLE_WIN, {
      totalStake: totalStakeRounded,
      possibleWinnings: nsoftMoney.getMainUnit(ticketWinningsRounded, getters.config.currency),
      possiblePayout: possiblePayoutRounded,
      possiblePayoutTax: possiblePayoutTaxRounded,
      possiblePayinTax: nsoftMoney.getMainUnit(totalPaymentTax, getters.config.currency),
    });
  },
  async fineTuningAlgorithm({ getters, dispatch, rootGetters }, previousRemainder) {
    const bets = getters.ticket.filter(bet => bet.stake < getters.maxBetPayment);
    const futureBets = bets.map(bet => bet.numEvents);
    const smallestBetBoxIncrement = getters.config.minIncrement * Math.min(...futureBets);

    if (previousRemainder === 0) return;

    if (previousRemainder < smallestBetBoxIncrement) {
      const correctValue = (Number(getters.totalPayment) - previousRemainder).toFixed(2);

      await dispatch('notifications/setNotification', {
        type: notificationTypes.CANT_SPLIT,
        message: template(rootGetters.translations.general_cant_split)({
          wrongValue: getters.totalPayment,
          correctValue,
        }),
        status: 'negative',
        notificationTimeout: getters.channelType === 'Retail' ? 3000 : null,
        callback: () => {
          dispatch('setTotalPayment', correctValue);
          dispatch('notifications/clearNotification', {
            type: notificationTypes.CANT_SPLIT,
          }, { root: true });
          dispatch('setIsCantSplitActive', false);
        },
      }, { root: true });

      dispatch('setIsCantSplitActive', true);

      return;
    }

    let betBoxIncrement = 0;
    let newRemainder = previousRemainder;

    for (let i = 0; i < bets.length; i += 1) {
      const bet = bets[i];

      if (bet.stake > getters.maxBetPayment) {
        // eslint-disable-next-line no-continue
        continue;
      }

      betBoxIncrement = getters.config.minIncrement * bet.numEvents;

      if (newRemainder < betBoxIncrement) {
        // eslint-disable-next-line no-continue
        continue;
      }

      const newBet = {
        ...bet,
        stake: getters.formatNumber(bet.stake + getters.config.minIncrement),
      };

      newRemainder = getters.formatNumber(newRemainder - betBoxIncrement);

      // eslint-disable-next-line no-await-in-loop
      await dispatch('updateBetValue', newBet);
    }

    dispatch('fineTuningAlgorithm', newRemainder);
  },
  async findDuplicateEventsAndLock({ dispatch, rootGetters }, ticket) {
    const tempTicket = cloneDeep(ticket);
    const eventIds = ticket.map(bet => bet.eventId);

    const undefinedValues = eventIds.filter(eventId => isNil(eventId));

    if (undefinedValues.length) return;

    const duplicates = eventIds.filter((eventId, index, arr) => arr.indexOf(eventId) !== index);

    const newTicket = tempTicket.map((bet) => {
      if (duplicates.includes(bet.eventId) || bet.permanentlyLock) {
        return {
          ...bet,
          locked: true,
        };
      }

      return {
        ...bet,
        locked: false,
      };
    });

    if (duplicates.length) {
      await dispatch('setIsPayinButtonDisabled', true);
      await dispatch('notifications/setNotification', {
        message: rootGetters.translations.general_bets_in_collision,
        status: 'negative',
      }, { root: true });
    } else {
      await dispatch('setIsPayinButtonDisabled', false);
      await dispatch('notifications/clearNotification', null, { root: true });
    }

    dispatch('setTicket', newTicket);
  },
  async findDuplicateEventsAndUnlock({ dispatch, rootGetters }, ticket) {
    const tempTicket = cloneDeep(ticket);

    const newTicket = tempTicket.map((bet) => {
      if (bet.permanentlyLock) {
        return {
          ...bet,
          locked: true,
        };
      }

      return {
        ...bet,
        locked: false,
      };
    });

    await dispatch('setIsPayinButtonDisabled', rootGetters.isBettingDisabled);
    await dispatch('notifications/clearNotification', null, { root: true });

    dispatch('setTicket', newTicket);
  },
  // Dummy format, game can set it's own format and override this one
  formatPlayerTickets(state, payload) {
    return payload.map((ticket) => {
      let bets = [];
      bets = ticket.bets.map(bet => ({
        id: bet.id,
        status: bet.status,
        round: bet.eventId,
        market: bet.typeValue,
        outcome: bet.value,
        stake: bet.amount,
        odd: bet.odd,
        eventValue: bet.eventValue,
        category: bet.category,
        system: bet.system,
      }));

      return {
        id: ticket.id,
        payout: ticket.payout,
        payin: ticket.payin,
        payinTax: ticket.payinTax,
        superBonus: ticket.superBonus,
        createdAt: ticket.createdAt,
        status: ticket.status,
        productName: ticket.product,
        maxPossibleWin: ticket.maxPossibleWin,
        bets,
        isFreeBet: ticket.isFreeBet,
        providerBetslipId: ticket.requestUuid,
      };
    });
  },
  async getLastTickets({ dispatch, rootGetters }) {
    const { config } = rootGetters;
    const user = rootGetters['user/user'];

    let responseData;

    try {
      dispatch('setIsLastTicketsInProgress', true);

      const { data } = await lastTickets.getLastTickets(config, user);

      responseData = data;
    } catch (error) {
      dispatch('notifications/setNotification', {
        message: error?.response?.data?.message ?? 'unknown_error',
        status: 'negative',
        notificationTimeout: true,
      });
    } finally {
      dispatch('setIsLastTicketsInProgress', false);
    }

    // eslint-disable-next-line no-underscore-dangle
    const isFormatDefined = Boolean(this._actions && this._actions.formatPlayerTickets);
    const formattedTickets = await dispatch('formatPlayerTickets', responseData, { root: isFormatDefined });

    dispatch('setPlayerTickets', formattedTickets);
  },
  async paymentPerBetDelete({ getters, dispatch }) {
    const newTickets = getters.ticket.map((bet) => {
      if (bet.combinations) {
        return {
          ...bet,
          stake: getters.combinationMinPayment(bet.combinations),
        };
      }

      return {
        ...bet,
        stake: getters.minBetPayment,
      };
    });

    await dispatch('setTicket', newTickets);
    await dispatch('setIsManualMode', false);
    await dispatch('calculateTotalPayment');
    await dispatch('setIsPaymentPerBetValid', true);
    dispatch('setPaymentPerBetValue', '');
  },
  async payIn(
    { getters, rootGetters }, { payload, additionalInfo = null, providerBetslipId = null },
  ) {
    const {
      ticket,
      totalPayment,
      isQuickpayEnabled,
      selectedBet,
    } = getters;
    const { config } = rootGetters;
    const user = rootGetters['user/user'];
    const ticketType = getters.activeTicketType.value;
    const bonus = pick(user?.bonuses[0], ['id', 'type', 'freeBetAmount']);

    let ticketForPayin;

    if (isQuickpayEnabled) {
      ticketForPayin = ticket.length ? ticket : [selectedBet];
    } else {
      ticketForPayin = ticket;
    }

    const betslip = {
      providerBetslipId,
      ticketType,
      ticket: ticketForPayin,
      payment: Number(totalPayment),
    };

    if (bonus.id && rootGetters.isBonusMode) betslip.bonuses = [bonus];
    return ticketApi.payin(config, user, betslip, payload, additionalInfo);
  },
  async removeBets({ commit, dispatch }) {
    commit(mutationTypes.REMOVE_BETS);

    // await dispatch('calculateTotalPayment');
    await dispatch('setPaymentPerBetValue', '');
    await dispatch('setIsManualMode', false);
    dispatch('setFutureRoundsValue', 1);
  },
  async removeBet({ commit, dispatch, getters }, id) {
    // When we have only one bet and we removed it
    // Reset all values to their default ones.
    if (getters.numberOfBets === 1) {
      await dispatch('setFutureRoundsValue', 1);
      await dispatch('setIsManualMode', false);
      await dispatch('setPaymentPerBetValue', '');

      dispatch('notifications/clearNotification', null, { root: true });
    }

    if (getters.numberOfBets === getters.maxBetNumber) {
      dispatch('notifications/clearNotification', { type: notificationTypes.MAX_BET_COUNT }, { root: true });
    }

    commit(mutationTypes.REMOVE_BET, id);
    if (getters.channelType === 'Retail') {
      await dispatch('setFutureRoundsForEqualEvents');
    }

    if (!getters.hasTicketLockedBets) {
      dispatch('notifications/clearNotification', {
        type: notificationTypes.INVALID_BETS,
      }, { root: true });
    }

    if (getters.isManualMode) {
      await dispatch('callTotalPaymentSplitAlgorithm');
    } else if (getters.isSingle) {
      await dispatch('calculateTotalPayment');

      if (getters.numberOfBets === 0) {
        // If there is no bets on betslip anymore
        // set total payment to default value
        await dispatch('setTotalPaymentValue', getters.rules.minBetAmount.value);
      }
    }

    if (getters.isCombo) {
      await dispatch('calculateTotalOdds');
      await dispatch('calculatePossibleWinCombo');
      dispatch('findDuplicateEventsAndLock', getters.ticket);
    }

    dispatch('validateAllBets');

    eventBus.$emit('formatBetslipInputValue');
  },
  resetStakes({ getters, dispatch }) {
    const newTicket = getters.ticket.map((bet) => {
      if (bet.combinations) {
        const combinationMinPayment = getters.combinationMinPayment(bet.combinations);
        const stake = combinationMinPayment < getters.minBetPayment
          ? getters.minBetPayment
          : combinationMinPayment;

        return {
          ...bet,
          stake,
        };
      }

      return {
        ...bet,
        stake: getters.minBetPayment,
      };
    });

    dispatch('setTicket', newTicket);
  },
  async rebet({ dispatch, getters, rootGetters }, ticket) {
    const clonedTicket = cloneDeep(ticket);
    const isSystemBet = (bet) => {
      if (clonedTicket.product === 'LuckySix') {
        return bet.type < 5 && bet.type > 0;
      }
      return !!bet.system;
    };
    const getCombinations = (bet) => {
      let outcomeLength;
      if (bet.outcome.value) {
        outcomeLength = bet.outcome.value.split(' ').length;
      } else {
        outcomeLength = bet.outcome.split(' ').length;
      }

      const combinationsBase = rootGetters.combinationsBase || bet.system;
      return helpers.getCombinations(outcomeLength, combinationsBase);
    };

    await dispatch('clearBetslip');

    const getRounds = bets => bets.map(bet => bet.round);

    const isFutureBet = (bets) => {
      const roundIds = getRounds(bets);
      return new Set(roundIds).size !== 1;
    };

    const findIndexesOfMultipleFutureBets = (bets) => {
      const roundIds = getRounds(bets);
      // we grab the starting round so we can differentiate multiple bets
      const targetRound = roundIds[0];
      const indexes = [];

      bets.forEach((bet, index) => {
        if (bet.round === targetRound) {
          indexes.push(index);
        }
      });

      return indexes;
    };

    const formatOutcome = outcome => String(outcome).split(',').join(' ');
    const containsNumber = str => /\d/.test(str);

    if (isFutureBet(clonedTicket.bets)) {
      const indexesOfFutureBets = findIndexesOfMultipleFutureBets(clonedTicket.bets);

      const multiBets = [];

      for (let i = 0; i < indexesOfFutureBets.length; i += 1) {
        const start = indexesOfFutureBets[i];
        const end = indexesOfFutureBets[i + 1];
        const chunk = clonedTicket.bets.slice(start, end);

        multiBets.push(chunk);
      }

      const promises = multiBets.map(async (bets) => {
        // all bets are the same, so we can safely use only first bet
        const bet = bets[0];

        setValue(bet, 'numEvents', bets.length);
        setValue(bet, 'stake', getters.formatNumber(bet.stake + bet.tax));
        setValue(bet, 'roundNumber', rootGetters.isNextRound ? rootGetters.roundId + 1 : rootGetters.roundId);
        setValue(bet, 'outcome', containsNumber(bet.outcome) ? formatOutcome(bet.outcome) : bet.outcome);

        if (isSystemBet(bet)) {
          setValue(bet, 'combinations', getCombinations(bet));
        }

        await dispatch('addBet', bet);

        if (getters.config.isFuturePerTicketAllowed && !getters.config.isFuturePerBetAllowed) {
          await dispatch('setFutureRounds', bets.length);
        }
      });

      await Promise.all(promises);
    } else {
      const promises = clonedTicket.bets.map(async (bet) => {
        setValue(bet, 'numEvents', 1);
        setValue(bet, 'stake', getters.formatNumber(bet.stake + bet.tax));
        setValue(bet, 'roundNumber', rootGetters.isNextRound ? rootGetters.roundId + 1 : rootGetters.roundId);
        setValue(bet, 'outcome', containsNumber(bet.outcome) ? formatOutcome(bet.outcome) : bet.outcome);

        if (isSystemBet(bet)) {
          setValue(bet, 'combinations', getCombinations(bet));
        }

        await dispatch('addBet', bet);
      });

      await Promise.all(promises);
    }

    dispatch('setTotalPaymentValue', clonedTicket.payin);

    eventBus.$emit('formatBetslipInputValue');
  },
  setIsQuickpayEnabled({ commit }, isEnabled) {
    commit(mutationTypes.SET_IS_QUICKPAY_ENABLED, isEnabled);
  },
  setIsPayinInProgress({ commit }, isInProgress) {
    commit(mutationTypes.SET_IS_PAYIN_IN_PROGRESS, isInProgress);
  },
  setIsLastTicketsInProgress({ commit }, isInProgress) {
    commit(mutationTypes.SET_IS_LAST_TICKETS_IN_PROGRESS, isInProgress);
  },
  setConfig({ commit }, config) {
    commit(mutationTypes.SET_CONFIG, config);
  },
  setActiveComponent({ commit }, component) {
    commit(mutationTypes.SET_ACTIVE_COMPONENT, component);
  },
  setActiveTicketType({ commit }, ticketType) {
    commit(mutationTypes.SET_ACTIVE_TICKET_TYPE, ticketType);
  },
  setIsPaymentPerBetValid({ commit }, isValid) {
    commit(mutationTypes.SET_IS_PAYMENT_PER_BET_VALID, isValid);
  },
  async setTotalPayment({ commit, dispatch }, totalPayment) {
    commit(mutationTypes.SET_TOTAL_PAYMENT, totalPayment);

    await dispatch('setIsManualMode', true);
    await dispatch('callTotalPaymentSplitAlgorithm');
    await dispatch('calculatePossibleWinPreBet');
    await dispatch('calculatePossibleWin');
    await dispatch('calculatePossibleWinCombo');
    await dispatch('setPaymentPerBetValue', '');
    await dispatch('validateAllBets');
    dispatch('validateTotalPayment');
  },
  setTotalPaymentValue({ commit, rootGetters }, totalPayment) {
    let totalPaymentValue = totalPayment;
    if (rootGetters.isFreeBetMode) {
      totalPaymentValue = rootGetters['user/freeBets'].betStake;
    }
    commit(mutationTypes.SET_TOTAL_PAYMENT, totalPaymentValue);
  },
  setMinTicketPayment({ commit }, minTicketPayment) {
    commit(mutationTypes.SET_MIN_TICKET_PAYMENT, minTicketPayment);
  },
  setMinBetPayment({ commit }, minBetPayment) {
    commit(mutationTypes.SET_MIN_BET_PAYMENT, minBetPayment);
  },
  setMinBetPaymentForBet({ getters }, bet) {
    let minPayment = getters.minBetPaymentForBet;

    if (!isNil(bet.combinations)) {
      const combinationMinPayment = Number(
        getters.combinationMinPayment(bet.combinations).toFixed(2),
      );
      minPayment = combinationMinPayment > getters.minBetPaymentForBet
        ? combinationMinPayment
        : getters.minBetPaymentForBet;
    }

    const finalStake = bet.stake > minPayment ? bet.stake : minPayment;

    setValue(bet, 'stake', finalStake);
  },
  async setPaymentPerBet({ commit, getters, dispatch }, paymentPerBet) {
    const newTickets = [];

    // Don't do anything if payment per bet input is empty
    if (paymentPerBet === '') return;

    commit(mutationTypes.SET_PAYMENT_PER_BET, paymentPerBet);
    // const isPaymentPerBetValid = await dispatch('validateBetPayment', {
    //  betStake: paymentPerBet
    // });

    // if (!isPaymentPerBetValid) return false;


    const validationPromises = getters.ticket.map(bet => dispatch('validatePaymentPerBet', {
      betStake: paymentPerBet,
      combinations: bet.combinations,
    }));
    const validations = await Promise.all(validationPromises);

    for (let i = 0; i < getters.ticket.length; i += 1) {
      const bet = getters.ticket[i];
      const { isValid, stake } = validations[i];

      const newTicket = { ...bet, stake, isValid };

      newTickets.push(newTicket);
    }

    await dispatch('setTicket', newTickets);
    await dispatch('setIsManualMode', false);

    // Enable payin button and clear notification
    // only when there are no invalid bets
    // We set payment validation here because if we put it inside
    // validateBetPayment, invalidBetPayments getter will be stale
    // and won't show correct state. This is the right time to call it
    // when we need to enable payin because bets are valid.
    if (!getters.invalidBetPayments.length) {
      dispatch('setIsPaymentValid', true);
      dispatch('notifications/clearNotification', {
        type: notificationTypes.BET_PAYMENT,
      }, { root: true });
    }

    dispatch('calculateTotalPayment');
    dispatch('validateAllBets');
  },
  setPaymentPerBetValue({ commit }, value) {
    commit(mutationTypes.SET_PAYMENT_PER_BET, value);
  },
  setTicket({ commit }, ticket) {
    commit(mutationTypes.SET_TICKET, ticket);
  },
  setIsValidBet({ commit }, value) {
    commit(mutationTypes.SET_IS_VALID_BET, value);
  },
  setFutureRoundsValue({ commit }, value) {
    commit(mutationTypes.SET_FUTURE_ROUNDS, value);
  },
  async setFutureRounds({ commit, getters, dispatch }, futureRounds) {
    const newTickets = getters.ticket.map(bet => ({
      ...bet,
      numEvents: futureRounds,
    }));

    commit(mutationTypes.SET_FUTURE_ROUNDS, futureRounds);

    await dispatch('setTicket', newTickets);

    if (getters.isManualMode) {
      await dispatch('callTotalPaymentSplitAlgorithm');
    } else {
      await dispatch('calculateTotalPayment');
    }

    eventBus.$emit('formatBetslipInputValue');
  },
  async setFutureRoundsPerBet({ commit, dispatch, getters }, { futureRounds, betId }) {
    commit(mutationTypes.SET_FUTURE_ROUNDS_PER_BET, { futureRounds, betId });

    if (getters.isManualMode) {
      await dispatch('callTotalPaymentSplitAlgorithm');
    } else {
      await dispatch('calculateTotalPayment');
    }

    eventBus.$emit('formatBetslipInputValue');
  },
  setIsManualMode({ commit }, isManualMode) {
    commit(mutationTypes.SET_IS_MANUAL_MODE, isManualMode);
  },
  setPossibleWinPreBet({ commit }, possibleWin) {
    commit(mutationTypes.SET_POSSIBLE_WIN_PRE_BET, possibleWin);
  },
  async setBetPayment({ commit, dispatch, getters }, { betStake, combinations, betId }) {
    commit(mutationTypes.SET_BET_PAYMENT, { betStake, betId });

    await dispatch('setPaymentPerBetValue', '');

    const isValid = await dispatch('validateBetPayment', { betStake, combinations, betId });

    await dispatch('setIsManualMode', false);
    await dispatch('setIsValidBet', { betId, isValid });

    // Enable payin button only when there are no invalid bets
    // We set payment validation here because if we put it inside
    // validateBetPayment, invalidBetPayments getter will be stale
    // and won't show correct state. This is the right time to call it
    // when we need to enable payin because bets are valid.
    if (!getters.invalidBetPayments.length) {
      dispatch('setIsPaymentValid', true);

      await dispatch('notifications/clearNotification', {
        type: notificationTypes.BET_PAYMENT,
      }, { root: true });
    }

    dispatch('calculateTotalPayment');
  },
  setPlayerTickets({ commit }, playerTickets) {
    commit(mutationTypes.SET_PLAYER_TICKETS, playerTickets);
  },
  setIsPayinButtonDisabled({ commit }, isDisabled) {
    commit(mutationTypes.SET_IS_PAYIN_BUTTON_DISABLED, isDisabled);
  },
  setIsPaymentPerBetValidation({ commit }, isPaymentPerBet) {
    commit(mutationTypes.SET_IS_PAYMENT_PER_BET_VALIDATION, isPaymentPerBet);
  },
  setActiveBetslipInput({ commit }, activeBetslipInput) {
    commit(mutationTypes.SET_ACTIVE_BETSLIP_INPUT, activeBetslipInput);
  },
  setIsStakeBoxVisible({ commit }, isStakeBoxVisible) {
    commit(mutationTypes.SET_IS_STAKE_BOX_VISIBLE, isStakeBoxVisible);
  },
  setIsBetAppearAnimationDisabled({ commit }, isDisabled) {
    commit(mutationTypes.SET_IS_BET_APPEAR_ANIMATION_DISABLED, isDisabled);
  },
  setRoundNumber({ commit }, { betId, roundNumber }) {
    commit(mutationTypes.SET_ROUND_NUMBER, { betId, roundNumber });
  },
  setIsTotalPaymentValid({ commit }, isValid) {
    commit(mutationTypes.SET_IS_TOTAL_PAYMENT_VALID, isValid);
  },
  setIsPaymentValid({ commit }, isValid) {
    commit(mutationTypes.SET_IS_PAYMENT_VALID, isValid);
  },
  setIsCantSplitActive({ commit }, isActive) {
    commit(mutationTypes.SET_IS_CANT_SPLIT_ACTIVE, isActive);
  },
  setIsUserBalanceInvalid({ commit }, isInvalid) {
    commit(mutationTypes.SET_IS_USER_BALANCE_INVALID, isInvalid);
  },
  setBetslipBlockers: ({ commit, state }, payload) => {
    let i = 0;
    const newBetslipBlockers = state.betslipBlockers;
    const checkArray = () => findIndex(
      newBetslipBlockers,
      blocker => blocker.id === payload.blockers[i].id,
    );
    while (i < payload.blockers.length) {
      const index = checkArray();
      if (payload.type === 'add') {
        if (index < 0) {
          newBetslipBlockers.push(payload.blockers[i]);
        }
      } else if (index >= 0) {
        newBetslipBlockers.splice(index, 1);
      }
      i += 1;
    }
    commit(mutationTypes.SET_BETSLIP_BLOCKERS, newBetslipBlockers);
  },
  setIsFreeBetPayinDisabled({ commit }, isDisabled) {
    commit(mutationTypes.SET_IS_FREE_BET_PAYIN_DISABLED, isDisabled);
  },
  async removeLockedBets({ dispatch, getters }) {
    // Bets can have undefine locked and permanently lock property
    // Handle those cases
    const unlockedBets = getters.ticket
      .filter(bet => (!isNil(bet.permanentlyLock) && !bet.permanentlyLock)
        || isNil(bet.permanentlyLock));

    await dispatch('setTicket', unlockedBets);

    if (getters.isSingle) {
      if (getters.isManualMode) {
        await dispatch('callTotalPaymentSplitAlgorithm');
      } else {
        await dispatch('calculateTotalPayment');
      }

      if (!getters.ticket.length) {
        dispatch('setIsManualMode', false);

        dispatch('setPaymentPerBetValue', '');

        await dispatch('setTotalPaymentValue', getters.minBetPayment);

        eventBus.$emit('formatBetslipInputValue');
      }
    }

    if (getters.isCombo) {
      dispatch('calculateTotalOdds');
      dispatch('calculatePossibleWinCombo');
      dispatch('findDuplicateEventsAndLock', getters.ticket);
    }
  },
  ticketCheck({ rootGetters }, requestUuid) {
    const { config } = rootGetters;
    const { platformName } = config;
    const user = rootGetters['user/user'];
    return platformName !== 'nextGen' ? ticketCheck.check(config, user, requestUuid) : ticketCheck.checkByProviderBetslipId(config, user, requestUuid);
  },
  async totalPaymentSplitAlgorithm({ getters, dispatch }, previousRemainder) {
    if (getters.isCombo) return;

    if (isNil(previousRemainder)) dispatch('resetStakes');

    // We need to clear can't split notification and set isCantSplitActive to false
    // when total payment can be splitted. We can't do it inside fineTuningAlgorithm action,
    // because it doesn't execute always.
    await dispatch('notifications/clearNotification', {
      type: notificationTypes.CANT_SPLIT,
    }, { root: true });
    await dispatch('setIsCantSplitActive', false);

    const bets = getters.ticket.filter(bet => bet.stake < getters.maxBetPayment);

    if (!bets.length) {
      // dispatch('removeBets');
      return;
    }

    const numberOfBets = bets
      .reduce((accumulator, currentValue) => accumulator + currentValue.numEvents, 0);

    const batchSize = numberOfBets * getters.config.minIncrement;

    const remainder = isNil(previousRemainder)
      ? Number(getters.totalPayment) - getters.minPaymentAllowed
      : previousRemainder;

    const availableBatches = Math.floor(getters.formatNumber(remainder) / batchSize);

    const payments = bets.map(bet => bet.stake);

    const allowedBatches = (getters.maxBetPayment - Math.max(...payments))
     / getters.config.minIncrement;

    const paymentAmount = Math
      .min(...[availableBatches, allowedBatches]) * getters.config.minIncrement;

    const newTicket = [];
    let paymentAmountSplitted = 0;

    for (let i = 0; i < getters.ticket.length; i += 1) {
      const bet = getters.ticket[i];
      let { stake } = bet;

      if (bet.stake < getters.maxBetPayment) {
        paymentAmountSplitted += paymentAmount * bet.numEvents;
        stake += paymentAmount;
      }

      newTicket.push({ ...bet, stake: getters.formatNumber(stake) });
    }

    dispatch('setTicket', newTicket);

    const newRemainder = getters
      .formatNumber(getters.formatNumber(remainder) - paymentAmountSplitted);

    if (newRemainder === 0) return;

    if (newRemainder < batchSize) {
      dispatch('fineTuningAlgorithm', newRemainder);
      return;
    }

    dispatch('totalPaymentSplitAlgorithm', newRemainder);
  },
  async totalPaymentDelete({ getters, dispatch }) {
    const newTickets = getters.ticket.map((bet) => {
      if (bet.combinations) {
        const combinationMinPayment = getters.combinationMinPayment(bet.combinations);
        const stake = combinationMinPayment < getters.minBetPayment
          ? getters.minBetPayment
          : combinationMinPayment;

        return {
          ...bet,
          stake,
          isValid: true,
        };
      }

      return {
        ...bet,
        stake: getters.minBetPayment,
        isValid: true,
      };
    });

    await dispatch('setTicket', newTickets);
    await dispatch('setPaymentPerBetValue', '');
    await dispatch('setTotalPayment', getters.minPaymentAllowed);
    await dispatch('setIsPaymentValid', true);
    await dispatch('setIsManualMode', false);
    dispatch('calculatePossibleWin');
  },
  async totalPaymentDeleteMax({ dispatch, getters }) {
    const newTickets = getters.ticket.map((bet) => {
      if (bet.combinations) {
        const combinationMinPayment = getters.combinationMinPayment(bet.combinations);
        const stake = combinationMinPayment < getters.minBetPayment
          ? getters.minBetPayment
          : combinationMinPayment;

        return {
          ...bet,
          stake,
          isValid: true,
        };
      }

      return {
        ...bet,
        stake: getters.minBetPayment,
        isValid: true,
      };
    });

    await dispatch('setTicket', newTickets);
    await dispatch('setPaymentPerBetValue', '');
    await dispatch('setTotalPayment', getters.maxPaymentAllowed);
    await dispatch('setIsPaymentValid', true);
    await dispatch('setIsManualMode', false);
    dispatch('calculatePossibleWin');
  },
  async updateBet({ commit, dispatch, getters }, newBet) {
    const isValidationSuccessful = await dispatch('validateUpdateBet', newBet);

    if (!isValidationSuccessful) return;

    commit(mutationTypes.UPDATE_BET, newBet);
    await dispatch('setMinBetPaymentForBet', newBet);

    if (getters.isSingle) {
      if (!getters.isManualMode) {
        await dispatch('calculateTotalPayment');
      }
    }

    if (getters.isCombo) {
      dispatch('calculateTotalOdds');
      dispatch('calculatePossibleWinCombo');
    }

    eventBus.$emit('formatBetslipInputValue');
  },
  async updateBetValue({ commit, dispatch }, newBet) {
    const isValidationSuccessful = await dispatch('validateUpdateBet', newBet);

    if (!isValidationSuccessful) return;

    commit(mutationTypes.UPDATE_BET, newBet);
  },
  async updateSelectedBet({ commit, dispatch }, newSelectedBet) {
    const isValidationSuccessful = await dispatch('validateUpdateBet', newSelectedBet);

    if (!isValidationSuccessful) return;

    commit(mutationTypes.UPDATE_SELECTED_BET, newSelectedBet);

    await dispatch('calculatePossibleWinPreBet');

    eventBus.$emit('formatBetslipInputValue');
  },
  async validatePaymentPerBet({ getters, dispatch, rootGetters }, { betStake, combinations }) {
    let minAmount = getters.minBetPayment;
    const notificationId = 'invalidTotalPayment';
    await dispatch('calculateTotalPaymentRange');

    if (betStake < minAmount) {
      return {
        isValid: false,
        stake: betStake,
      };
    }

    if (!isNil(combinations)) {
      const combinationMinPayment = Number(getters.combinationMinPayment(combinations).toFixed(2));
      minAmount = combinationMinPayment > getters.minBetPayment
        ? combinationMinPayment
        : getters.minBetPayment;

      if (betStake < minAmount) {
        return {
          isValid: true,
          stake: combinationMinPayment,
        };
      }
    }

    const maxAmount = getters.maxBetPayment;

    if (Number(betStake) < getters.formatNumber(getters.minBetPayment)) {
      await dispatch('notifications/setNotification', {
        type: notificationTypes.BET_PAYMENT,
        message: rootGetters.translations.general_minimum_stake_apply.supplant({
          value: getters.minPaymentAllowed.toFixed(2),
        }),
        callback: async () => {
          await dispatch('totalPaymentDelete');
          eventBus.$emit('formatBetslipInputValue');
        },
        status: 'negative',
        id: notificationId,
      }, { root: true });

      dispatch('setIsPaymentValid', false);

      // eslint-disable-next-line consistent-return
      return {
        isValid: false,
        stake: betStake,
      };
    }

    if (Number(betStake) > maxAmount) {
      await dispatch('notifications/setNotification', {
        type: notificationTypes.BET_PAYMENT,
        message: rootGetters.translations.general_maximum_stake_apply.supplant({
          value: getters.maxPaymentAllowed.toFixed(2),
        }),
        status: 'negative',
        id: notificationId,
        callback: async () => {
          await dispatch('totalPaymentDeleteMax');
          eventBus.$emit('formatBetslipInputValue');
        },
      }, { root: true });


      dispatch('setIsPaymentValid', false);

      // eslint-disable-next-line consistent-return
      return {
        isValid: false,
        stake: betStake,
      };
    }

    // eslint-disable-next-line consistent-return
    return {
      isValid: true,
      stake: betStake,
    };
  },
  async validateBetPayment({ dispatch, getters, rootGetters }, { betStake, combinations, betId }) {
    const notificationId = `invalidBetPayment${betId}`;
    // We don't have payment per bet on combo ticket
    // Don't validate bet payments
    if (getters.isCombo) return;

    let minAmount = getters.minBetPayment;

    await dispatch('calculateTotalPaymentRange');

    if (!isNil(combinations)) {
      const combinationMinPayment = getters.combinationMinPayment(combinations);
      minAmount = combinationMinPayment > getters.minBetPayment
        ? combinationMinPayment
        : getters.minBetPayment;
    }

    const maxAmount = getters.maxBetPayment;

    if (Number(betStake) < getters.formatNumber(minAmount)) {
      const message = getters.channelType !== 'Retail' ? rootGetters.translations.general_minimum_stake_apply.supplant({
        value: getters.minPaymentAllowed.toFixed(2),
      }) : rootGetters.translations.general_min_payment_per_bet_rule.supplant({
        value: getters.formatNumber(minAmount).toFixed(2),
      });
      await dispatch('notifications/setNotification', {
        type: notificationTypes.BET_PAYMENT,
        message,
        id: notificationId,
        callback: async () => {
          await dispatch('totalPaymentDelete');
          eventBus.$emit('formatBetslipInputValue');
        },
        status: 'negative',
      }, { root: true });


      dispatch('setIsPaymentValid', false);

      // eslint-disable-next-line consistent-return
      return false;
    }

    if (Number(betStake) > maxAmount) {
      const message = getters.channelType !== 'Retail' ? rootGetters.translations.general_maximum_stake_apply.supplant({
        value: getters.maxPaymentAllowed.toFixed(2),
      }) : rootGetters.translations.general_max_payment_per_bet_rule.supplant({
        value: maxAmount.toFixed(2),
      });
      await dispatch('notifications/setNotification', {
        type: notificationTypes.BET_PAYMENT,
        message,
        id: notificationId,
        status: 'negative',
        callback: async () => {
          await dispatch('totalPaymentDeleteMax');
          eventBus.$emit('formatBetslipInputValue');
        },
      }, { root: true });

      dispatch('setIsPaymentValid', false);

      // eslint-disable-next-line consistent-return
      return false;
    }
    eventBus.$emit('CloseNotification', notificationId);
    // eslint-disable-next-line consistent-return
    return true;
  },
  async validateUserBalance({ dispatch, getters, rootGetters }) {
    const userBalance = rootGetters['user/balance'];
    const isUserLoggedIn = rootGetters['user/isLoggedIn'];
    const isTerminalUser = rootGetters['user/isTerminalUser'];
    const { isBettingDisabled } = rootGetters;

    if (isUserLoggedIn || isTerminalUser) {
      const isPreBetActive = getters.preBet.bet && getters.preBet.bet.market;

      if (userBalance < Number(getters.totalPayment)
        && (getters.numberOfBets > 0 || isPreBetActive)
        && !rootGetters.isFreeBetMode
      ) {
        await dispatch('notifications/clearNotification', null, { root: true });

        await dispatch('notifications/setNotification', {
          type: notificationTypes.USER_BALANCE,
          message: rootGetters.translations.general_not_enough_money,
          status: 'negative',
        }, { root: true });

        dispatch('setIsUserBalanceInvalid', true);

        eventBus.$emit('UserBalanceInvalid');

        return false;
      }

      if (!isBettingDisabled) {
        dispatch('notifications/clearNotification', {
          type: notificationTypes.USER_BALANCE,
        }, { root: true });
        dispatch('setIsUserBalanceInvalid', false);
      }

      eventBus.$emit('UserBalanceValid');
    }

    return true;
  },
  async validateAllBets({ getters, dispatch }) {
    // We don't have payment per bet on combo ticket
    // Don't validate bet payments
    if (getters.isCombo) return;

    const newTickets = [];

    const validationPromises = getters.ticket.map(bet => dispatch('validateBetPayment', {
      betStake: bet.stake,
      combinations: bet.combinations,
    }));
    const validations = await Promise.all(validationPromises);

    for (let i = 0; i < getters.ticket.length; i += 1) {
      const bet = getters.ticket[i];
      const isValid = validations[i];

      const newTicket = { ...bet, isValid };

      newTickets.push(newTicket);
    }

    await dispatch('setTicket', newTickets);

    // Enable payin button only when there are no invalid bets
    // We set payment validation here because if we put it inside
    // validateBetPayment, invalidBetPayments getter will be stale
    // and won't show correct state. This is the right time to call it
    // when we need to enable payin because bets are valid.
    if (!getters.invalidBetPayments.length) {
      await dispatch('setIsPaymentValid', true);
      await dispatch('notifications/clearNotification', {
        type: notificationTypes.BET_PAYMENT,
      }, { root: true });
      eventBus.$emit('CloseNotification', 'all');
    }
  },
  async validateTotalPayment({ dispatch, getters, rootGetters }) {
    const notificationId = 'invalidTotalPayment';
    // Show invalid single bet payment notifications before showing total payment validation
    // if (getters.invalidBetPayments.length) return;
    await dispatch('calculateTotalPaymentRange');

    const isValidBalance = await dispatch('validateUserBalance');

    if (!isValidBalance) return;

    if (rootGetters.isFreeBetMode) {
      if (!rootGetters['gamesBetslip/ticket'].length
        && rootGetters['gamesBetslip/preBet']?.bet?.market) {
        if (Number(getters.totalPayment) > Number(getters.maxBetPayment)) {
          await dispatch('notifications/setNotification', {
            message: rootGetters.translations.general_free_bet_invalid_total_payment,
            notificationTimeout: 5000,
            status: 'negative',
          }, { root: true });
          await dispatch('setIsFreeBetPayinDisabled', true);
          await dispatch('setIsTotalPaymentValid', false);

          // eslint-disable-next-line consistent-return
          return false;
        }
      }
    }

    if (Number(getters.totalPayment) < getters.formatNumber(getters.minPaymentAllowed)) {
      await dispatch('notifications/setNotification', {
        type: notificationTypes.TOTAL_PAYMENT,
        message: rootGetters.translations.general_minimum_stake_apply.supplant({
          value: getters.minPaymentAllowed.toFixed(2),
        }),
        id: notificationId,
        status: 'negative',
        callback: async () => {
          await dispatch('totalPaymentDelete');
          eventBus.$emit('formatBetslipInputValue');
        },
      }, { root: true });

      await dispatch('setIsTotalPaymentValid', false);

      // eslint-disable-next-line consistent-return
      return false;
    }

    if (
      Number(getters.totalPayment) > getters.maxPaymentAllowed && getters.maxPaymentAllowed !== 0
    ) {
      await dispatch('notifications/setNotification', {
        type: notificationTypes.TOTAL_PAYMENT,
        message: rootGetters.translations.general_maximum_stake_apply.supplant({
          value: getters.maxPaymentAllowed.toFixed(2),
        }),
        status: 'negative',
        id: notificationId,
        callback: async () => {
          await dispatch('totalPaymentDeleteMax');
          eventBus.$emit('formatBetslipInputValue');
        },
      }, { root: true });

      await dispatch('setIsTotalPaymentValid', false);

      // eslint-disable-next-line consistent-return
      return false;
    }

    // Don't clear individual bets notification if there
    // are invalid bets
    if (!getters.invalidBetPayments.length) {
      await dispatch('notifications/clearNotification', {
        type: notificationTypes.TOTAL_PAYMENT,
      }, { root: true });
      await dispatch('setIsTotalPaymentValid', true);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (getters.isSingle) {
        await dispatch('setIsTotalPaymentValid', false);
      }
    }
    eventBus.$emit('CloseNotification', notificationId);
    // eslint-disable-next-line consistent-return
    return true;
  },
  async validateAddBet({ dispatch }, bet) {
    const isMaxBetNumberReached = await dispatch('validateMaxBetNumber');
    const isBetFormatValid = await dispatch('validateBetFormat', bet);

    if (isMaxBetNumberReached || !isBetFormatValid) return false;

    return true;
  },
  async validateUpdateBet({ dispatch }, newBet) {
    const isBetFormatValid = await dispatch('validateBetFormat', newBet);

    if (!isBetFormatValid) return false;

    return true;
  },
  async validateBetFormat({ rootGetters, dispatch }, bet) {
    const notificationId = 'invalidBetFormat';
    const {
      market,
      outcome,
      roundNumber,
    } = bet;


    if (
      (isNil(market) || !isString(market))
      || (isNil(outcome) || !isString(outcome))
      || (isNil(roundNumber) || !isNumber(roundNumber))
    ) {
      await dispatch('notifications/setNotification', {
        message: rootGetters.translations.general_bet_invalid,
        notificationTimeout: 5000,
        status: 'negative',
        id: notificationId,
      }, { root: true });

      return false;
    }
    eventBus.$emit('CloseNotification', notificationId);
    return true;
  },
  async validateMaxBetNumber({ getters, dispatch, rootGetters }) {
    const { maxBetNumber } = getters;

    if (getters.numberOfBets >= maxBetNumber) {
      await dispatch('notifications/setNotification', {
        type: notificationTypes.MAX_BET_COUNT,
        message: rootGetters.translations.general_max_bet_count_rule.supplant({
          value: maxBetNumber,
        }),
        notificationTimeout: 5000,
        status: 'negative',
      }, { root: true });

      return true;
    }

    return false;
  },
  async setFutureRoundsForEqualEvents({ getters, dispatch, commit }) {
    const bets = getters.ticket;
    const firstBet = bets[0];

    if (bets.length === 0) {
      await dispatch('clearBetslip');
      return;
    }

    for (let i = 0; i < bets.length; i += 1) {
      if (bets[i].numEvents !== firstBet.numEvents) {
        commit(mutationTypes.SET_FUTURE_ROUNDS, '');
        return;
      }
    }
    await dispatch('setFutureRounds', firstBet.numEvents);
  },
  async validateFreeBet({ getters }, bet) {
    return Number(bet.stake) === Number(getters.minBetPayment);
  },
};
