import { httpTicketCheck } from './http';
import gameHeaders from './headers';

export default {
  getEndpoint(platformName, config) {
    const platform = platformName ? platformName.toUpperCase() : 'SEVEN';
    const appName = config.applicationName === 'Terminal' ? 'TERMINAL' : 'WEB';
    const path = `VUE_APP_${platform}_${appName}_TICKET_CHECK_PATH`;
    return process.env[path];
  },
  check(config, user, requestUuid) {
    const { platformName, productName } = config;
    const url = this.getEndpoint(platformName, config);
    const headers = {
      ...gameHeaders.getAuthHeader(user, config),
      ...gameHeaders.getPlatformHeaders(user, config, requestUuid),
    };

    return httpTicketCheck(config).get(`${url}/${requestUuid}/product/${productName}/check.json`, { headers });
  },
  checkByProviderBetslipId(config, user, providerBetslipId) {
    const { platformName, productName } = config;
    const url = this.getEndpoint(platformName, config);
    const headers = {
      ...gameHeaders.getAuthHeader(user, config),
      ...gameHeaders.getPlatformHeaders(user, config, providerBetslipId),
    };

    return httpTicketCheck(config).get(`${url}?uuid=${providerBetslipId}&product=${productName}`, { headers });
  },
};
